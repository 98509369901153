<template>
<div class="container">
    <div id="content"></div>
  </div>
</template>

<script>

export default {
  components: {
  },
  mounted() {
      // https://john-dugan.com/javascript-debounce/
function debounce(e, t, n) {
  var a;
  return function () {
    var r = this,
      i = arguments,
      o = function () {
        a = null, n || e.apply(r, i)
      },
      s = n && !a;
    clearTimeout(a), a = setTimeout(o, t || 200), s && e.apply(r, i)
  }
}

const texts = [
    `<div class="services__offres__text"><h3 class="services__offres__title">SITE VITRINE</h3><p class="services__offres__text">Un site vitrine est un site web qui présente en ligne vos produits ou services dans le but d'attirer simplement l'attention et d'éveiller l'intérêt des internautes de passage. La plupart des sites ne nécessitant pas de connexion sont des sites vitrines. Ils contiennent les informations essentielles (adresse, contact, produits, services...) qui peuvent être montées sur une seule page (monopage) ou plusieurs (multipages). Les sites vitrines peuvent être également conçus sous forme d'application monopage (qui permet l'affichage dynamique des différentes pages, comme ce site par exemple) ou à l’aide de Wordpress (qui permet à l’utilisateur d’avoir une liberté de modification simple et autonome).</p></div><div class="services__offres__dotblock"><i class="fas fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">SITE E-COMMERCE</h3><p class="services__offres__text">Un site e-commerce est un site qui vous permettra de vendre vos produits en ligne, offrant à votre clientèle la possibilité d'être livrée ou de récupérer ses biens en click&collect. Je crée vos sites e-commerce avec le CMS Prestashop, qui est une application open source contenant tout un back-office de gestion de la boutique en ligne (catalogue, base client, stock, paiement, livraison), vous permettant d'avoir une boutique en ligne clé en main.</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="fas fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">SITE WORDPRESS</h3><p class="services__offres__text">Un site Wordpress est un site qui utilise le CMS Wordpress (Content Management System, logiciel qui permet de concevoir, gérer et mettre à jour des sites web). J'utilise Wordpress pour les sites s'orientant autour d'une page de blog ou d'articles d'informations, mais également pour créer des sites vitrines de toutes sortes. Wordpress, avec son back-office simple d’utilisation, permet au client de pouvoir faire des modifications des contenus du site en autonomie, ce qui lui offre une grande liberté de manœuvre. Bien sûr, une session de prise en main de Wordpress vous sera proposé lorsque vous me commandez un site de ce type !</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="fas fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">REFONTE DE SITE</h3><p class="services__offres__text">La refonte d'un site web consiste, à partir d'un site existant, à modifier la structure ainsi que le design du site pour suivre les évolutions des tendances internet et celles de votre entreprise. C'est une étape importante, car avoir un site à jour, moderne et avec des fonctionnalités optimisées aura un impact sur votre taux de conversion et sur votre visibilité. La refonte peut être totale, et sera assimilée à la création d'un nouveau site, ou partielle (modification du design uniquement ou optimisations responsives uniquement par exemple).</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">SEO</h3><p class="services__offres__text">Le SEO, ou Search Engine Optimisation, est la manière de positionner un site internet sur les moteurs de recherche. Qui ne voudrait pas être en tête des résultats Google ? Les optimisations SEO sont des techniques de référencement naturel permettant de gagner des places sur les moteurs de recherche et donc d'améliorer votre visibilité. Un site est bien optimisé, référencé, lorsqu'il apparaît dans les premiers résultats pour les requêtes ciblées.</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">NOM DE DOMAINE<br>ET HEBERGEMENT</h3><p class="services__offres__text">Un nom de domaine est l'équivalent de votre adresse postale sur internet, il correspond à ce que vous voyez dans la barre d'adresse et permet d'arriver à bon port sur votre site. Il est nécessaire de le réserver auprès de différents bureaux d'enregistrement. Attention : premier arrivé, premier servi ! Il est ensuite indispensable de prendre un abonnement d'hébergement dans l’un des différents organismes qui le proposent (OVH, IONOS, etc), ce qui vous permettra de mettre en ligne votre site sur votre domaine. Ces opérations ne sont pas gratuites mais représentent un petit budget, comptez un peu moins d’une centaine d’euros par an en fonction des services choisis.
    </p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">RESPONSIVE DESIGN</h3><p class="services__offres__text">Le responsive design est une technique de conception des sites web qui permet leur affichage adapté à différents supports : smartphones, tablettes, ordinateurs... Il permet d'améliorer l'ergonomie du site et sa prise en main, ainsi que son référencement naturel. Il est en effet très important aujourd'hui d'avoir un site pensé pour être visualisé sur mobile : depuis plusieurs années maintenant, plus de personnes consultent internet sur mobile plutôt que sur ordinateur. Pour avoir un exemple concret de responsive design, je vous invite à visiter ce site depuis différents supports ! LM Créations Numériques s'engage à ne créer que des sites responsives.</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i></div>`,
     `<div class="services__offres__text"><h3 class="services__offres__title">MAQUETTAGE GRAPHIQUE</h3><p class="services__offres__text">Le maquettage graphique est l'étape de fabrication de la maquette graphique du site. C'est un schéma reprenant les idées du cahier des charges et de la charte graphique pour les mettre dans la forme du futur site web. La maquette commence souvent par un croquis sur papier, pour ensuite être traitée numériquement. Elle reprend donc sous forme d'image statique la structure, les formes, les polices, les couleurs... du site pour vous permettre de le visualiser et de le valider avant de passer à l'intégration web.</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i><i class="far fa-circle"></i></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">REDACTION WEB</h3><p class="services__offres__text">La rédaction web consiste à rédiger des contenus pour vos pages web. Elle est un élément marketing à ne pas négliger car elle fait partie des optimisations SEO. Les textes doivent être courts, concis et précis mais évidemment informatifs. Pour des sites à thème, la rédaction web peut suivre une charte éditoriale. Parfois, vous êtes la personne la mieux qualifiée pour parler de votre marque... Mais sinon, LM Créations Numériques s'en chargera pour vous !</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i><i class="far fa-circle"></i></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">CREATION DE CONTENU</h3><p class="services__offres__text">La création de contenu dans le cadre d'un site web, consiste à produire les médias qui accompagneront la rédaction web. Leur mission est double : faire partie intégrante du design mais aussi faire passer des informations. Parfois, ces médias ont seulement un but illustratif. Ces contenus peuvent être audiovisuels ou alors des infographies, photographies, illustrations... Je me chargerai, selon vos besoins, vos envies et votre budget, de les créer ou de les sélectionner pour vous !</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i><i class="far fa-circle"></i></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">PERIODE DE GARANTIE</h3><p class="services__offres__text">Une période de garantie d’une durée de 3 mois vous est offerte dès la fin de la réalisation de votre site. Pendant cette période de garantie, je m’engage à corriger ou résoudre tous problèmes ou bugs, issus uniquement de la conception du site. Toutes modifications du code source par un tiers ou par le propriétaire du site annulent automatiquement la période de garantie. Les délais d’interventions en cas de problèmes varient en fonction de l’urgence. Pour plus d’informations, consultez mes Conditions Générales de Vente.</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i></div>`,
     `<div class="services__offres__text"><h3 class="services__offres__title">DEVELOPPEMENT WEB<br>ECO-RESPONSABLE</h3><p class="services__offres__text">Vous le savez peut-être, le numérique a une empreinte carbone conséquente : on dit que 1 requête Google consommerai autant qu’une ampoule allumée 1 heure ! En effet, pour fonctionner, Internet se base sur des serveurs qui sont énergivores, que ce soit pour stocker les données, ou les envoyer vers le poste de l’utilisateur. Plus un site sera lourd, plus son impact écologique sera conséquent, car il nécessitera plus de ressources à chaque consultation. Quand on est développeur web et que l’on créé des sites destinés à être consultés des centaines ou des milliers de fois, il faut avoir conscience de cet impact, et créer des sites les moins lourds possible. Comment faire ? En n’utilisant pas de librairies ou autres plug-in inutiles, ni des CMS (comme Wordpress) lorsqu’ils ne servent pas au client. En travaillant « de zéro » en HTML, CSS et Javascript, notamment pour les sites vitrine, vous obtiendrez un site tout léger et minifié, avec un très faible impact écologique !</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i></div>`,
    `<div class="services__offres__text"><h3 class="services__offres__title">MAINTENANCE</h3><p class="services__offres__text">Je vous propose un abonnement à la maintenance pour les sites créés. Les prestations incluses dans cet abonnement sont : la résolution de problèmes, dans les mêmes délais que lors de la période de garantie (voir CGV), les mises à jour des extensions, modules et versions des CMS (Wordpress, Prestashop) et les modifications mineures de votre site (ex : changement d’une adresse, ajout d’un lien en pied de page, mise à jour d’un logo…). N'hésitez pas à m'en parler lors de la création de votre site !</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i></div>`
  ]

const stepSize = window.innerHeight

function setText(contentTag, text) {
  contentTag.innerHTML = text
}

function getIndex(distance, stepSize) {
  return parseInt(distance / stepSize)
}

function getText(texts, index) {
  return texts[index] ? texts[index] : `<div class="services__offres__text"><h3 class="services__offres__title">MAINTENANCE</h3><p class="services__offres__text">Je vous propose un abonnement à la maintenance pour les sites créés. Les prestations incluses dans cet abonnement sont : la résolution de problèmes, dans les mêmes délais que lors de la période de garantie (voir CGV), les mises à jour des extensions, modules et versions des CMS (Wordpress, Prestashop) et les modifications mineures de votre site (ex : changement d’une adresse, ajout d’un lien en pied de page, mise à jour d’un logo…). N'hésitez pas à m'en parler lors de la création de votre site !</p></div><div class="services__offres__dotblock"><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="far fa-circle"></i><i class="fas fa-circle"></i></div>`
}

function changeText(contentTag, stepSize, texts) {
  const distance = window.scrollY -700
  const index = getIndex(distance, stepSize)
  const text = getText(texts, index)
  setText(contentTag, text)
}

function setInittext(contentTag, texts) {
  const text = getText(texts, 0)
  setText(contentTag, text)
}

function loadText() {
  const contentTag = document.querySelector('#content')
  setInittext(contentTag, texts)
  window.addEventListener('scroll', debounce(() => { changeText(contentTag, stepSize, texts) }, 20))
}

loadText();
  }
}
</script>

<style lang="scss">

.container {
  height: auto; 
}


#content {
    width: 420px;
    color: black;
    text-align: center;
    display: flex;
    align-items: center;
}

.services__offres__dotblock {
  display: flex;
    flex-direction: column;
    margin-left: 25px;
}

.fa-circle {
  color: #26262696;
    font-size: 6px;
  margin: 5px 0;
}
</style>