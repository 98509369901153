<template>
  <div class="home">
    <section id="homehead">
      <h1 class="maintitle">CREATION<br>SITE WEB</h1>      
      <p class="maintext">Pour voguer sereinement sur l’océan du web, il est aujourd'hui indispensable d'avoir un site internet. LM Créations Numériques vous propose plusieurs offres de conception de site web pour s'adapter à vos besoins et à votre budget.</p>
    </section>
    <section id="anchor" class="services__card">
      <anchor-router-link class="services__card__one skewleft" :to="{hash: '#services__offres'}" :scrollOptions="{
                                    container: `body`,
                                    duration: 700,
                                    easing: `ease`,
                                    offset: -100,
                                    cancelable: true,
                                    onStart: false,
                                    onDone: false,
                                    onCancel: false,
                                    x: false,
                                    y: true}">
                                    <div class="services__card__content skewright">
              <i class="fas fa-tasks services__card__icon"></i>
              <h2 class="services__card__title">LES OFFRES</h2></div>
      </anchor-router-link>
      <anchor-router-link class="services__card__one__center skewright" :to="{hash: '#services__process'}" :scrollOptions="{
                                    container: `body`,
                                    duration: 700,
                                    easing: `ease`,
                                    offset: -100,
                                    cancelable: true,
                                    onStart: false,
                                    onDone: false,
                                    onCancel: false,
                                    x: false,
                                    y: true}">
                                    <div class="services__card__content center skewleft">
        <i class="fas fa-cogs services__card__icon"></i>
        <h2 class="services__card__title">LE PROCESS</h2>
        </div>
      </anchor-router-link>
      <anchor-router-link class="services__card__one skewleft" :to="{hash: '#services__price'}" :scrollOptions="{
                                    container: `body`,
                                    duration: 700,
                                    easing: `ease`,
                                    offset: -100,
                                    cancelable: true,
                                    onStart: false,
                                    onDone: false,
                                    onCancel: false,
                                    x: false,
                                    y: true}">
                                    <div class="services__card__content skewright">
        <i class="fas fa-money-bill-wave services__card__icon"></i>
        <h2 class="services__card__title">LES TARIFS</h2>
        </div>
      </anchor-router-link>
    </section>
    <section id="services__offres" class="soweb">
      <h2 class="services__title">LES OFFRES</h2>
      <div class="sticky">
      <img src="../assets/responsive3.svg" alt="Illustration d'un site responsive sur ordinateur, tablette et mobile, avec des illustrations autour." class="sticky__img">      
      <Sitestext/>
      </div>
      <div class="sticky__no">
        <img src="../assets/responsive3.svg" alt="Illustration d'un site responsive sur ordinateur, tablette et mobile, avec des illustrations autour." class="services__offres__img revealleft">
        <div class="services__offres__line revealright">
          <h3 class="services__offres__title">SITE VITRINE</h3>
          <p class="services__offres__text">Un site vitrine est un site web qui présente en ligne vos produits ou services dans le but d'attirer simplement l'attention et d'éveiller l'intérêt des internautes de passage. La plupart des sites ne nécessitant pas de connexion sont des sites vitrines. Ils contiennent les informations essentielles (adresse, contact, produits, services...) qui peuvent être montées sur une seule page (monopage) ou plusieurs (multipages). Les sites vitrines peuvent être également conçus sous forme d'application monopage (qui permet l'affichage dynamique des différentes pages, comme ce site par exemple) ou à l’aide de Wordpress (qui permet à l’utilisateur d’avoir une liberté de modification simple et autonome).</p>
        </div>
        <div class="services__offres__line revealleft">
          <h3 class="services__offres__title">SITE E-COMMERCE</h3>
          <p class="services__offres__text">Un site e-commerce est un site qui vous permettra de vendre vos produits en ligne, offrant à votre clientèle la possibilité d'être livrée ou de récupérer ses biens en click&collect. Je crée vos sites e-commerce avec le CMS Prestashop, qui est une application open source contenant tout un back-office de gestion de la boutique en ligne (catalogue, base client, stock, paiement, livraison), vous permettant d'avoir une boutique en ligne clé en main.</p>
        </div>
        <div class="services__offres__line revealright">
          <h3 class="services__offres__title">SITE WORDPRESS</h3>
          <p class="services__offres__text">Un site Wordpress est un site qui utilise le CMS Wordpress (Content Management System, logiciel qui permet de concevoir, gérer et mettre à jour des sites web). J'utilise Wordpress pour les sites s'orientant autour d'une page de blog ou d'articles d'informations, mais également pour créer des sites vitrines de toutes sortes. Wordpress, avec son back-office simple d’utilisation, permet au client de pouvoir faire des modifications des contenus du site en autonomie, ce qui lui offre une grande liberté de manœuvre. Bien sûr, une session de prise en main de Wordpress vous sera proposé lorsque vous me commandez un site de ce type !</p>
        </div>
        <div class="services__offres__line revealleft">
          <h3 class="services__offres__title">REFONTE DE SITE</h3>
          <p class="services__offres__text">La refonte d'un site web consiste, à partir d'un site existant, à modifier la structure ainsi que le design du site pour suivre les évolutions des tendances internet et celles de votre entreprise. C'est une étape importante, car avoir un site à jour, moderne et avec des fonctionnalités optimisées aura un impact sur votre taux de conversion et sur votre visibilité. La refonte peut être totale, et sera assimilée à la création d'un nouveau site, ou partielle (modification du design uniquement ou optimisations responsives uniquement par exemple).</p>
        </div>
        <div class="services__offres__line revealright">
          <h3 class="services__offres__title">SEO</h3>
          <p class="services__offres__text">Le SEO, ou Search Engine Optimisation, est la manière de positionner un site internet sur les moteurs de recherche. Qui ne voudrait pas être en tête des résultats Google ? Les optimisations SEO sont des techniques de référencement naturel permettant de gagner des places sur les moteurs de recherche et donc d'améliorer votre visibilité. Un site est bien optimisé, référencé, lorsqu'il apparaît dans les premiers résultats pour les requêtes ciblées.</p>
        </div>
        <div class="services__offres__line revealleft">
          <h3 class="services__offres__title">NOM DE DOMAINE ET HEBERGEMENT</h3>
          <p class="services__offres__text">Un nom de domaine est l'équivalent de votre adresse postale sur internet, il correspond à ce que vous voyez dans la barre d'adresse et permet d'arriver à bon port sur votre site. Il est nécessaire de le réserver auprès de différents bureaux d'enregistrement. Attention : premier arrivé, premier servi ! Il est ensuite indispensable de prendre un abonnement d'hébergement dans l’un des différents organismes qui le proposent (OVH, IONOS, etc), ce qui vous permettra de mettre en ligne votre site sur votre domaine. Ces opérations ne sont pas gratuites mais représentent un petit budget, comptez un peu moins d’une centaine d’euros par an en fonction des services choisis.</p>
        </div>
        <div class="services__offres__line revealright">
          <h3 class="services__offres__title">RESPONSIVE DESIGN</h3>
          <p class="services__offres__text">Le responsive design est une technique de conception des sites web qui permet leur affichage adapté à différents supports : smartphones, tablettes, ordinateurs... Il permet d'améliorer l'ergonomie du site et sa prise en main, ainsi que son référencement naturel. Il est en effet très important aujourd'hui d'avoir un site pensé pour être visualisé sur mobile : depuis plusieurs années maintenant, plus de personnes consultent internet sur mobile plutôt que sur ordinateur. Pour avoir un exemple concret de responsive design, je vous invite à visiter ce site depuis différents supports ! LM Créations Numériques s'engage à ne créer que des sites responsives.</p>
        </div>
        <div class="services__offres__line revealleft">
          <h3 class="services__offres__title">MAQUETTAGE GRAPHIQUE</h3>
          <p class="services__offres__text">Le maquettage graphique est l'étape de fabrication de la maquette graphique du site. C'est un schéma reprenant les idées du cahier des charges et de la charte graphique pour les mettre dans la forme du futur site web. La maquette commence souvent par un croquis sur papier, pour ensuite être traitée numériquement. Elle reprend donc sous forme d'image statique la structure, les formes, les polices, les couleurs... du site pour vous permettre de le visualiser et de le valider avant de passer à l'intégration web.</p>
        </div>
        <div class="services__offres__line revealright">
          <h3 class="services__offres__title">REDACTION WEB</h3>
          <p class="services__offres__text">La rédaction web consiste à rédiger des contenus pour vos pages web. Elle est un élément marketing à ne pas négliger car elle fait partie des optimisations SEO. Les textes doivent être courts, concis et précis mais évidemment informatifs. Pour des sites à thème, la rédaction web peut suivre une charte éditoriale. Parfois, vous êtes la personne la mieux qualifiée pour parler de votre marque... Mais sinon, LM Créations Numériques s'en chargera pour vous !</p>
        </div>
        <div class="services__offres__line revealleft">
          <h3 class="services__offres__title">CREATION DE CONTENU</h3>
          <p class="services__offres__text">La création de contenu dans le cadre d'un site web, consiste à produire les médias qui accompagneront la rédaction web. Leur mission est double : faire partie intégrante du design mais aussi faire passer des informations. Parfois, ces médias ont seulement un but illustratif. Ces contenus peuvent être audiovisuels ou alors des infographies, photographies, illustrations... LM Créations Numériques, selon vos besoins, vos envies et votre budget, se chargera de les créer ou de les sélectionner pour vous !</p>
        </div>
        <div class="services__offres__line revealright">
          <h3 class="services__offres__title">PERIODE DE GARANTIE</h3>
          <p class="services__offres__text">Une période de garantie d’une durée de 3 mois vous est offerte dès la fin de la réalisation de votre site. Pendant cette période de garantie, je m’engage à corriger ou résoudre tous problèmes ou bugs, issus uniquement de la conception du site. Toutes modifications du code source par un tiers ou par le propriétaire du site annulent automatiquement la période de garantie. Les délais d’interventions en cas de problèmes varient en fonction de l’urgence. Pour plus d’informations, consultez mes Conditions Générales de Vente.</p>
        </div>
        <div class="services__offres__line revealleft">
          <h3 class="services__offres__title">MAINTENANCE</h3>
          <p class="services__offres__text">Je vous propose un abonnement à la maintenance pour les sites créés. Les prestations incluses dans cet abonnement sont : la résolution de problèmes, dans les mêmes délais que lors de la période de garantie (voir CGV), les mises à jour des extensions, modules et versions des CMS (Wordpress, Prestashop) et les modifications mineures de votre site (ex : changement d’une adresse, ajout d’un lien en pied de page, mise à jour d’un logo…). N'hésitez pas à m'en parler lors de la création de votre site !</p>
        </div>
        <div class="services__offres__line revealright">
          <h3 class="services__offres__title">DEVELOPPEMENT WEB ECO-RESPONSABLE</h3>
          <p class="services__offres__text">Vous le savez peut-être, le numérique a une empreinte carbone conséquente : on dit que 1 requête Google consommerai autant qu’une ampoule allumée 1 heure ! En effet, pour fonctionner, Internet se base sur des serveurs qui sont énergivores, que ce soit pour stocker les données, ou les envoyer vers le poste de l’utilisateur. Plus un site sera lourd, plus son impact écologique sera conséquent, car il nécessitera plus de ressources à chaque consultation. Quand on est développeur web et que l’on créé des sites destinés à être consultés des centaines ou des milliers de fois, il faut avoir conscience de cet impact, et créer des sites les moins lourds possible. Comment faire ? En n’utilisant pas de librairies ou autres plug-in inutiles, ni des CMS (comme Wordpress) lorsqu’ils ne servent pas au client. En travaillant « de zéro » en HTML, CSS et Javascript, notamment pour les sites vitrine, vous obtiendrez un site tout léger et minifié, avec un très faible impact écologique !</p>
        </div>
      </div> 
    </section>
    <section id="services__process" class="slideshow">
      <Processslideshow/>
    </section>
    <section id="services__price">
      <h2 class="services__title">LES TARIFS</h2>
    <Tarifsweb/>
    </section>
  </div>
</template>

<script>
import Processslideshow from '../components/processslideshow.vue';
import Sitestext from '../components/sitestext.vue';
import Tarifsweb from '../components/tarifsweb.vue';
import $ from 'jquery';
import AnchorRouterLink from 'vue-anchor-router-link'

export default {
  name: 'Home',
  components: {
    Processslideshow,
    AnchorRouterLink,
    Tarifsweb,
    Sitestext
  },
  mounted() {
    /********** Apparition au scroll ****/
        var rafId = null;
        var delay = 230;
        var lTime = 0;

        function scroll() {
          var scrollTop = $(window).scrollTop();
          var height = $(window).height()
          var visibleTop = scrollTop + height;
          $('.revealleft').each(function() {
            var $t = $(this);
            if ($t.hasClass('reveal_visible')) { return; }
            var top = $t.offset().top;
            if (top <= visibleTop) {
              if (top + $t.height() < scrollTop) {
                $t.removeClass('reveal_pending').addClass('reveal_visible');
              } else {
                $t.addClass('reveal_pending');
                if (!rafId) requestAnimationFrame(reveal);  
              }
            }
          });
          $('.revealright').each(function() {
            var $t = $(this);
            if ($t.hasClass('reveal_visible')) { return; }
            var top = $t.offset().top;
            if (top <= visibleTop) {
              if (top + $t.height() < scrollTop) {
                $t.removeClass('reveal_pending').addClass('reveal_visible');
              } else {
                $t.addClass('reveal_pending');
                if (!rafId) requestAnimationFrame(reveal);  
              }
            }
          });
        }
        function reveal() {
          rafId = null;
          var now = performance.now();
          
          if (now - lTime > delay) {
            lTime = now;
            var $ts = $('.reveal_pending');
            $($ts.get(0)).removeClass('reveal_pending').addClass('reveal_visible');  
          }
          
          
          if ($('.reveal_pending').length >= 1) rafId = requestAnimationFrame(reveal);
        }

        $(scroll);
        $(window).scroll(scroll);
  }
}
</script>

<style lang="scss">

/******************** CARTES HAUT DE PAGE */

.services__card {
    background-image: url('../assets/wavej4.svg');
    background-size: auto;
    background-position-y: center;
    background-repeat: repeat-x;
    padding-top: 100px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  @media screen and (max-width: 980px){
            background-size: cover;
            flex-direction: column;
            padding-top: 50px;
            padding-bottom: 0;
            }
  &__container {
  display: flex;
  justify-content: space-evenly;
  box-sizing: border-box;
  width: 80%;
  margin: auto;
  }
  &__one {
    width: 25%;
    box-shadow: 0px 0px 6px 0px grey;
    border-radius: 50px;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    box-sizing: border-box;
    transition: all 0.25s;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    z-index: 50;
    //background: linear-gradient(127deg, #ffffff66 0%, #1967a314 90%, #ffffff66 110%);
    &:hover {
      transform: scale(1.03) skewY(13deg);
    }
    @media (max-width: 980px) {
      width: 90%;
      margin: auto;
      margin-bottom: 50px;
    }
    @media (min-width: 1440px) {
      padding: 50px 50px;
    }
    &__center {
       width: 25%;
    box-shadow: 0px 0px 6px 0px grey;
    border-radius: 50px;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    box-sizing: border-box;
    transition: all 0.25s;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    z-index: 50;
    //background: linear-gradient(127deg, #ffffff66 0%, #1967a314 90%, #ffffff66 110%);
      &:hover {
      transform: scale(1.03) skewY(-13deg);
    }
    @media (max-width: 980px) {
      width: 90%;
      margin: auto;
      margin-bottom: 50px;
      transform: skewY(13deg)!important;
      &:hover {
      transform: scale(1.03) skewY(13deg);
        }
      }
     @media (min-width: 1440px) {
      padding: 50px 50px;
    }
    }
  }
  &__title {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: xx-large;
    text-align: center;
  }
  &__icon {
  font-size: 400%;
  background: linear-gradient(308deg, #1c09e0cf 0%, #38e01fc7 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  }
}

.skewleft {
  transform: skewY(13deg);
  display: flex;
    flex-direction: column;
    align-items: center;
}

.skewright {
  transform: skewY(-13deg);
  display: flex;
    flex-direction: column;
    align-items: center;
}

.center {
   @media (max-width: 980px) {
      transform: skewY(-13deg)!important;
   }
}

/********************* INTRO SUR IMAGE HAUT DE PAGE */

.maintitle {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-size: 8vw;
  color: white;
  margin-bottom: 0;
  @media (max-width: 1440px) {
		font-size: 8vw;
	}
  @media (max-width: 1024px) {
		font-size: 10vw;
	}
  @media (max-width: 768px) {
		font-size: 10vw;
	}
  @media (max-width: 425px) {
		font-size: 10vw;
	}
  @media (max-width: 300px) {
		font-size: 10vw;
	}
}

#homehead {
  width: 80%;
  margin: auto;
  height: 73vh;
}

.maintext {
  font-family: 'Open Sans', sans-serif;
  color: white;
  width: 60%;
  border-top: 6px solid white;
  padding-top: 24px;
  @media (max-width: 1440px) {
		font-size: medium;
    width: 60%;
	}
  @media (max-width: 1024px) {
		font-size: medium;
    width: 80%;
	}
  @media (max-width: 768px) {
		font-size: small;
    width: 80%;
	}
  @media (max-width: 425px) {
		font-size: small;
    width: 80%;
	}
  @media (max-width: 300px) {
		font-size: small;
    width: 80%;
	}
}

/********************* BLOC OFFRES */


#services__offres {
  position: relative;
  width: 100%;
  margin: auto;
  @media (max-width: 768px) {
    width: 95%;
    height: auto;
	}
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    width: 95%;
    height: auto;
  }
}

.soweb {
  height: 10000px;
  @media (max-width: 769px) {
    height: auto;
	}
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    height: auto;
  }
}


.sticky {
  position: sticky;
  display: flex;
  top: 50%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  background-image: url('../assets/wave2j1.svg');
  background-size: auto;
  background-position-y: center;
  background-repeat: repeat-x;
  -webkit-transform: translate(0,-50%);
  transform: translate(0,-50%);
  margin-top: 30%;
  margin-bottom: -10%;
  @media screen and (max-width: 768px){
            display: none;
            }
  @media only screen and (min-device-width : 769px) and (max-device-width : 1024px) and (orientation : portrait) {
    display: none;
  }
  &__img {
    width: 40%;
  }
  &__no {
    width: 90%;
    margin: auto;
        display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    overflow: hidden;
    @media screen and (min-width: 769px){
            display: none;
            }
    @media only screen and (min-device-width : 769px) and (max-device-width : 1024px) and (orientation : portrait) {
    width: 90%;
    margin: auto;
        display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    overflow: hidden; ;
    }
  }
}

.services__offres {
  &__title {
        font-family: 'Yanone Kaffeesatz', sans-serif;
        font-size: 40px;
        margin-top: 30px;
        margin-bottom: 10px;
        position: relative;
        width: 100%;
        text-align: left;
        &::before {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        top: calc( 50% - 1.5px );
        border-bottom: 20px solid #38e01f;
        z-index: -1;
        }
        @media screen and (max-width: 980px){
                margin-top: 60px;
                }
    }
    &__text {
      font-size: small;
      text-align: left;
    }
    &__img {
      width: 90%;
      margin: auto;
    margin-top: 20px;
    }

}

/****************** BLOC PRIX */

#services__price {
  width: 100%
}

 .services__title {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 70px;
    margin-top: 0;
    margin-bottom: 30px;
    position: relative;
    width: 90%;
    margin: auto;
    &::before {
      content: '';
    width: 100%;
    position: absolute;
    left: 0;
    top: calc( 50% - 1.5px );
    border-bottom: 30px solid #38e01f;
    z-index: -1;
    }
    @media screen and (max-width: 980px){
            margin-top: 60px;
            }
  }

/********************* APPARITION AU SCROLL */

.revealleft {
  opacity: 0;
  transform: translate3d(-100px, 0, 0);
  transition: opacity 0.8s, transform 0.8s;
}

.revealright {
  opacity: 0;
  transform: translate3d(100px, 0, 0);
  transition: opacity 0.8s, transform 0.8s;
}

.reveal_visible {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
</style>
